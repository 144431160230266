import { EditorWrapper } from "../components/EditorWrapper";
import { EditorPageApp } from "../editorPage/App";

const App = () => {
  return (
    <>
      <EditorWrapper>
        <EditorPageApp />
      </EditorWrapper>
    </>
  );
};

export default App;
